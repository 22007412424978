import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLocation } from '@reach/router';
import { breakpoints } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import getExtensionFromUrl from '../../../utils/getExtensionFromUrl';
import { isUserAgentMobile } from '../../../utils/getUserAgent';

const bgColorMap = {
  Light: {
    bg: colors.base.heroSurface,
    heading: colors.gray[900],
    kicker: colors.primary[500],
    copy: colors.gray[600],
    copyAccent: colors.gray[600],
  },
  Blue: {
    bg: colors.primary[900],
    heading: colors.base.white,
    kicker: colors.base.white,
    copy: colors.gray[100],
    copyAccent: colors.base.white,
  },
  Charcoal: {
    bg: colors.gray[900],
    heading: colors.base.white,
    kicker: colors.base.white,
    copy: colors.gray[100],
    copyAccent: colors.base.white,
  },
  Cera: {
    bg: colors.base.heroSurface,
    heading: colors.gray[900],
    kicker: colors.gray[500],
    copy: colors.gray[600],
    copyAccent: colors.gray[600],
  },
  Green: {
    bg: colors.green[800],
    heading: colors.base.white,
    kicker: colors.base.white,
    copy: colors.gray[100],
    copyAccent: colors.base.white,
  },
};

const useHeroConditionals = component => {
  const { internalName, heroVariant, background, reference } = component;
  const location = useLocation();

  const websiteForm =
    reference &&
    reference.filter(column => column.__typename === 'DatoCmsWebsiteForm');

  const isFullWidth = heroVariant === 'full-width' ?? false;
  const isSmaller = useMediaQuery(`(max-width: ${breakpoints.xl})`);
  const isLanding = location.pathname.includes('landing');
  const isBlue = background === 'Blue';
  const isIntegration = internalName.toLowerCase().includes('integrations');
  const isAnimated = getExtensionFromUrl(reference[0]?.image?.url) === 'json';
  const mobileFormFlag =
    useMediaQuery(`(max-width: ${breakpoints.md})`) &&
    websiteForm &&
    websiteForm.length > 0 &&
    (location.pathname.includes('demo') ||
      location.pathname.includes('general-c') ||
      // AB testing
      location.pathname === '/general-f' ||
      // AB testing
      location.pathname === '/general-c-autocomplete' ||
      location.pathname === '/get-started') &&
    isUserAgentMobile();
  const colorMap = bgColorMap[background];

  colorMap.ctaColorOverride = component.ctaColorOverride;

  return {
    colorMap,
    isAnimated,
    isBlue,
    isFullWidth,
    isIntegration,
    isLanding,
    isSmaller,
    mobileFormFlag,
  };
};

export default useHeroConditionals;
